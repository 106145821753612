@keyframes popOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes popIn {
  0% {
    visibility: visible;
    transform: scale(0);
  }

  100% {
    visibility: visible;
    transform: scale(1);
  }
}

body {
  color: #ededed;
  letter-spacing: .03rem;
  background-color: #1b4b23;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5rem;
  transition: background-color .2s;
  overflow-x: hidden;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.3rem;
}

a {
  color: #5d5d5d;
  text-decoration: none;
}

a:hover {
  color: #fff;
  background-color: #00f;
  padding: 8px 0;
}

a:visited {
  color: #9b9b9b;
  text-decoration: none;
}

input[type="button"] {
  color: #535353;
  border: none;
  border-radius: 30px;
  padding: 16px 32px;
  font-size: 16px;
  transition: all .3s;
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
}

input[type="button"]:hover {
  cursor: pointer;
  transition: all .3s;
  transform: scale(1.05);
  box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
}

input[type="button"]:active {
  cursor: wait;
  transition: all 50ms cubic-bezier(.19, .83, .45, .96);
  box-shadow: 0 0 0 1px #0000000d;
}

header {
  height: 80px;
  width: 100%;
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
}

header #logo {
  height: 30%;
  transition: all .2s;
  position: absolute;
  top: 70px;
}

@media (max-width: 900px) {
  header #logo {
    top: 26px;
  }
}

header #logo.scrolled {
  animation: popOut .6s cubic-bezier(.83, -.74, .86, -.33) forwards;
}

main {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0;
}

main section:nth-child(2n) {
  flex-direction: row-reverse;
}

@media (max-width: 900px) {
  main section:nth-child(2n) {
    width: 100%;
    flex-direction: column-reverse;
  }
}

main section:first-child {
  margin-top: 10%;
}

@media (max-width: 900px) {
  main section:first-child {
    margin-top: 0%;
  }
}

main section:first-child img {
  width: 32%;
}

@media (max-width: 900px) {
  main section:first-child img {
    width: 70%;
  }
}

main section {
  height: 60vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 80px;
  margin: 40px;
  padding: 180px 0;
  display: flex;
}

@media (max-width: 900px) {
  main section {
    height: 100vh;
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
}

main section img {
  width: 50%;
  max-width: 500px;
  z-index: 1;
  transform: scale(1.9);
}

@media (max-width: 900px) {
  main section img {
    width: 100%;
    gap: 0;
  }
}

@media (min-width: 1600px) {
  main section img {
    max-height: none;
    width: 90%;
  }
}

main section div {
  z-index: 10;
  max-width: 280px;
  color: #ffffffe6;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #ffffff0d;
  border: 2px solid #dddddd2e;
  border-top-color: #ddd6;
  border-left: 3px solid #dddddd42;
  border-radius: 30px;
  flex-direction: column;
  gap: 1.4rem;
  padding: 28px 32px;
  display: flex;
  box-shadow: 0 4px 12px #0000001a;
}

@media (max-width: 900px) {
  main section div {
    gap: .5rem;
    padding: 20px 24px;
    font-size: 1.05rem;
    line-height: 1.4;
  }

  main section div h2 {
    font-size: 1.6rem;
    line-height: 1.29;
  }
}

main div#user-uploaded-images {
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  display: flex;
  overflow: hidden;
}

main div#user-uploaded-images .uploaded-image {
  max-width: 800px;
  margin: 40px 0;
  overflow: hidden;
}

main div#upload-button-container {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 180px 0 130px;
  display: flex;
}

@media (max-width: 900px) {
  main div#upload-button-container {
    margin: 120px auto;
  }
}

.tray-container {
  height: 144px;
  width: 220px;
  z-index: 99;
  cursor: default;
  border-radius: 30px;
  position: fixed;
  bottom: 20px;
  left: 50px;
}

@media (max-width: 900px) {
  .tray-container {
    bottom: 10px;
    left: 30px;
  }
}

.tray-container .tray {
  z-index: 100;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  width: 200px;
  height: auto;
  background-color: #f3f3f31c;
  border: 2px solid #dddddd4d;
  border-color: #dddddd80 #dddddd4d #dddddd4d #dddddd5c;
  border-radius: 30px;
  flex-flow: wrap;
  align-items: center;
  gap: 2px;
  padding: 10px;
  display: flex;
  position: absolute;
  box-shadow: 0 13px 27px -5px #32325d40, 0 8px 16px -8px #0000004d;
}

@media (max-width: 900px) {
  .tray-container .tray {
    width: 200px;
    height: 80px;
  }
}

.tray-container .sticker-tray img {
  width: 26px;
  height: auto;
  border: 2px solid #0000;
  flex-shrink: 1;
  margin: 4px;
  padding: auto;
  transition: all .2s cubic-bezier(.5, -.24, .71, 1.06);
}

.tray-container .sticker-tray img:last-child {
  visibility: hidden;
}

.tray-container .sticker-tray img:last-child.scrolled {
  animation: popIn .6s cubic-bezier(.06, 1.28, .44, 1.77) .7s forwards;
}

.tray-container .sticker-tray img:hover {
  cursor: pointer;
  background-color: #ededed;
  border: 2px solid #ededed;
  border-radius: 999px;
  margin: 0;
  padding: 4px;
  transition: all .2s cubic-bezier(.5, -.24, .71, 1.06);
  transform: scale(1.4);
}

.tray-container .brush-tray {
  width: auto;
  z-index: 98;
  visibility: hidden;
  cursor: default;
  gap: 24px;
  padding: 6px 8px;
  transition: all .2s;
  position: absolute;
  bottom: 43px;
}

@media (max-width: 900px) {
  .tray-container .brush-tray {
    height: 24px;
  }
}

.tray-container .brush-tray .selector {
  height: 24px;
  width: 24px;
  background-color: #ededed;
  border-radius: 999px;
  transition: all .3s cubic-bezier(.58, -.88, .59, 1.19);
}

.tray-container .brush-tray .selector:hover {
  cursor: pointer;
  transition: all .3s cubic-bezier(.58, -.88, .59, 1.19);
  transform: scale(1.3);
}

.tray-container .brush-tray #medium {
  height: 18px;
  width: 18px;
}

.tray-container .brush-tray #small {
  height: 12px;
  width: 12px;
}

div.stickers {
  width: 50%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 50%;
}

div.stickers img {
  width: 48px;
  height: auto;
  position: absolute;
  transform: translate(-50%, -50%);
}

footer {
  color: #000;
  justify-content: center;
  margin: 80px 40px;
  font-size: 14px;
  display: flex;
}

@media (max-width: 900px) {
  footer {
    margin-bottom: 200px;
  }
}

.hidden {
  visibility: hidden;
}

/*# sourceMappingURL=index.5d7c6cc1.css.map */

$font-size: 18px;
$font-weight: 400;
$font-leading: 1.5rem;

$h1-size: 3.2rem;

$h2-size: 2rem;
$h2-leading: 2.3rem;
$h2-weight: 700;

$h3-size: 1.33rem;

$footer-font-size: 14px;

$color-light: #ededed;
$color-dark: #0d0e0e;

@keyframes popOut {
  0% {
    transform: scale(1);
    opacity: 1
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes popIn {
  0% {
    transform: scale(0);
    visibility: visible;
  }
  100% {
    transform: scale(1);
    visibility: visible;
  }
}

@mixin respond-medium {
  @media (max-width: 900px) {
    @content
  }
}

@mixin respond-above1600 {
  @media (min-width: 1600px) {
    @content
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #1b4b23;
  color: $color-light;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $font-leading;
  transition: background-color 200ms ease;
  letter-spacing: .03rem;
  overflow-x: hidden;
}

h1 {
  font-size: $h1-size;
}

h2 {
  font-size: $h2-size;
  line-height: $h2-leading;
  font-weight: $h2-weight;
}

a{
  text-decoration: none;
  color: rgb(93, 93, 93);
}

a:hover{
  background-color: blue;
  color: white;
  padding: 8px 0px;
}

a:visited{
  color: rgb(155, 155, 155);
  text-decoration: none;
}

input[type="button"] {
  padding: 16px 32px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  color: #535353;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  transition: all 300ms ease;
}

input[type="button"]:hover {
  cursor:pointer;
  transform: scale(1.05);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  transition: all 300ms ease;
}

input[type="button"]:active {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  transition: all 50ms cubic-bezier(.19,.83,.45,.96);
  cursor: wait;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  height: 80px;
  width: 100%;
  z-index: 100;

  #logo {
    height: 30%;
    transition: all 200ms ease;
    top: 70px;
    position: absolute;

    @include respond-medium {
      top: 26;
    }
  }

  #logo.scrolled {
    animation: popOut 600ms forwards cubic-bezier(.83, -0.74, .86, -0.33);
  }
}

main {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0;

  section:nth-child(even) {
    flex-direction: row-reverse;

    @include respond-medium {
      flex-direction: column-reverse;
      width: 100%;
    }
  }

  section:first-child {
    margin-top: 10%;

    @include respond-medium {
      margin-top: 0%;
    }
    img{
      width: 32%;
    }
    img {
      @include respond-medium {
        width: 70%;
      }
    }
  }

  section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
    padding: 180px 0px;
    height: 60vh;
    margin: 40px;

    @include respond-medium {
      flex-direction: column-reverse;
      align-items: center;
      gap: 0rem;
      height: 100vh;
      padding: 0px 0px;
      margin: 0px;
      overflow-x: hidden;
    }

    img {
      transform: scale(1.9);
      width: 50%;
      max-width: 500px;
      z-index: 1;

      @include respond-medium {
        width: 100%;
        gap: 0;
      }

      @include respond-above1600() {
        max-height: none;
        width: 90%;
        ;
      }
    }

    div {
      z-index: 10;
      display: flex;
      flex-direction: column;
      gap: 1.4rem;
      max-width: 280px;

      color: rgba(255, 255, 255, 0.9);

      padding: 28px 32px;

      border-top: 2px solid rgba(221, 221, 221, 0.40);
      border-left: 3px solid rgba(221, 221, 221, 0.26);
      border-bottom: 2px solid rgba(221, 221, 221, 0.18);
      border-right: 2px solid rgba(221, 221, 221, 0.18);
      border-radius: 30px;

      backdrop-filter: blur(5px);
      background-color: rgba(255, 255, 255, 0.05);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

      @include respond-medium {
        font-size: 1.05rem;
        gap: 0.5rem;
        padding: 20px 24px;
        line-height: 1.4;

        h2 {
          font-size: 1.6rem;
          line-height: 1.29;
        }
      }
    }
  }

  div#user-uploaded-images {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .uploaded-image {
      margin: 40px 0;
      max-width: 800px;
      overflow: hidden;
    }
  }

  div#upload-button-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 180px 0px 130px 0;

    @include respond-medium {
      margin: 120px auto;
    }
  }
}

.tray-container {
  position: fixed;
  bottom: 20px;
  left: 50px;
  border-radius: 30px;
  height: 144px;
  width: 220px;
  z-index: 99;
  cursor: default;

  @include respond-medium {
    bottom: 10px;
    left: 30px;
  }

  .tray {
    z-index: 100;
    position: absolute;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;

    border: 1px solid rgba(221, 221, 221, 0.22);
    border-top: 2px solid rgba(221, 221, 221, 0.50);
    border-left: 2px solid rgba(221, 221, 221, 0.36);
    border-bottom: 2px solid rgba(221, 221, 221, 0.3);
    border-right: 2px solid rgba(221, 221, 221, 0.3);

    backdrop-filter: blur(4px);
    background-color: rgba(243, 243, 243, 0.11);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    border-radius: 30px;
    gap: 2px;
    width: 200px;
    height: auto;
    padding: 10px 10px;


    @include respond-medium {
      width: 200px;
      height: 80px;
    }
  }

  .sticker-tray {
    img {
      width: 26px;
      height: auto;
      padding: auto;
      margin: 4px;
      flex-shrink: 1;
      border: 2px solid transparent;
      transition: all 200ms cubic-bezier(.5, -0.24, .71, 1.06);

    }

    img:last-child {
      visibility: hidden;
    }

    img:last-child.scrolled {
      animation: popIn 600ms forwards cubic-bezier(.06, 1.28, .44, 1.77);
      animation-delay: 700ms;
    }

    img:hover {
      border: 2px solid $color-light;
      margin: 0;
      transform: scale(1.4);
      padding: 4px;
      border-radius: 999px;
      transition: all 200ms cubic-bezier(.5, -0.24, .71, 1.06);

      cursor: pointer;
      background-color: $color-light;

    }

  }

  .brush-tray {
    position: absolute;
    bottom: 43px;
    gap: 24px;
    width: auto;
    padding: 6px 8px;
    transition: all 200ms ease;
    z-index: 98;
    visibility: hidden;
    cursor: default;

    @include respond-medium {
      height: 24px;
    }

    .selector {
      background-color: $color-light;
      height: 24px;
      width: 24px;
      border-radius: 999px;
      transition: all 300ms cubic-bezier(.58, -0.88, .59, 1.19);
    }

    .selector:hover {
      transform: scale(1.3);
      cursor: pointer;
      transition: all 300ms cubic-bezier(.58, -0.88, .59, 1.19);
    }

    #medium {
      height: 18px;
      width: 18px;
    }

    #small {
      height: 12px;
      width: 12px;
    }
  }

}

div.stickers {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  z-index: 5;

  img {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 48px;
    height: auto;

  }
}

footer {
  color: black;
  display: flex;
  justify-content: center;
  margin: 80px 40px;
  font-size: $footer-font-size;
  @include respond-medium {
    margin-bottom: 200px;
  }
}

.hidden {
  visibility: hidden;
}